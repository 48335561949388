import React from "react";
import Review from "./Review";
import "../blocks/ReviewModule.css";

function ReviewModule({ reviews }) {
  return (
    <div className="review-component">
      <div>
        <h2 className="review-component__title">
          Clientes reales, resultados reales
        </h2>
        <div className="review-module">
          {reviews.map((review, index) => (
            <Review
              key={index}
              image={review.image}
              name={review.name}
              text={review.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReviewModule;
