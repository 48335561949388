import React from "react";
import Card from "./Card";

import "../blocks/CardModule.css";

import image1 from "../images/trainers/juancarlos_thecrew.jpg";
import image2 from "../images/trainers/xavi_lift_thecrew.jpg";
import image3 from "../images/trainers/daniel_petras_thecrew.jpg";
import image4 from "../images/trainers/raquel_molina_thecrew.jpg";

function CardModuleBook() {
  return (
    <section id="personal-trainers" className="card-component">
      <div>
        <h2 className="card-component__title">Reserva una sesión de prueba</h2>
        <h3 className="card-component__subtitle">
          Nuestros entrenadores personales te ayudarán a conseguir tus objetivos
        </h3>
        <div className="card-module">
          <Card
            image={image1}
            title="Juan Carlos González"
            description={[
              "Pérdida de masa grasa",
              "Aumento de músculo",
              "Técnica y adaptación de ejercicios",
              "Optimizar tu alimentación",
              "Preparación para competición natural",
            ]}
            buttonText="Reserva una sesión de prueba"
            to="/juancarlos"
          />

          <Card
            image={image2}
            title="Xavi Lift"
            description={[
              "Recomposición corporal",
              "Mejora de condición física",
              "Metodología sostenible",
              "Bienestar general",
              "Musculación",
            ]}
            buttonText="Reserva una sesión de prueba"
            to="/xavilift"
          />
        </div>
      </div>
    </section>
  );
}

export default CardModuleBook;
