import React from "react";
import "../../blocks/Packages/TrainerProfile.css";
import "@fortawesome/fontawesome-free/css/all.css";

import Form from "../Form";
import CardModule from "../CardModule";

import image1 from "../../images/trainers/xavi_lift_thecrew.jpg";

function XaviLift() {
  return (
    <>
      {/* ------ BLOCK NEW HEADER -------- */}

      <div className="profile_intro_container">
        <div className="profile_intro_data">
          <div className="profile_intro_card">
            <div className="card_body">
              <div>
                <img
                  className="card_image"
                  src={image1}
                  alt="Juan Carlos González"
                />
              </div>
              <div>
                <h2 className="card_title">Xavi Lift</h2>
                <p className="card_subtitle">Entrenador Personal</p>
                {/* <div className="social_icons">

                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fas fa-link"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="profile_intro_contact">
          <div className="form_container">
            <div className="contact_form_profilepage">
              <Form endpoint="xavilift" />
            </div>
          </div>
        </div>
        <div className="profile_intro_description">
          <div className="profile_intro_description_text">
            <p className="profile_intro_description_section">
              Una Misión Centrada en Ti
            </p>
            <p className="profile_intro_description_content">
              Especialista en recomposición corporal (perdida grasa , aumento
              fuerza), buscando así una mejora de la condición física y mental,
              teniendo mejores hábitos con una metodología sostenible y que se
              adhiera a tus condiciones.
            </p>
          </div>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <div className="profile_intro_content">
            <h2>Bonos de Entrenamiento Personal</h2>
            <h3 className="profile_intro_services_package">Pareja</h3>
            <ul>
              <li>1 entrenamiento 60€</li>
              <li>4 entrenamientos 220€ (55€)</li>
              <li>8 entrenamientos 400€ (50€)</li>
              <li>12 entrenamientos 540€ (45€)</li>
            </ul>

            <h3 className="profile_intro_services_package">Individual</h3>
            <ul>
              <li>1 entrenamiento 45€ </li>
              <li>4 entrenamientos 160€ (40€)</li>
              <li>8 entrenamientos 280€ (35€)</li>
              <li>12 entrenamientos 360€ (30€)</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <h2>Semipresencial: 90€</h2>
          <p>
            Seguimiento del progreso. Rutina mensual personalizada. 1
            entrenamiento al mes. 1 medición corporal. Asesoramiento nutricional
          </p>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <Form endpoint="xavilift" />
        </div>
      </div>
      {/* ------ BLOCK OTHER TRAINERS -------- */}
      <div className="package-page__block-other-trainers">
        <CardModule />
      </div>
    </>
  );
}

export default XaviLift;
