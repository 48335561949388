import React, { useState, useEffect, useRef } from "react";
import "../blocks/Form.css";

import animationData from "../utils/118202-success-icon.json";

import lottie from "lottie-web";

function Form({ endpoint }) {
  const baseURL =
    process.env.NODE_ENV === "production"
      ? "https://www.thecrewmallorca.com"
      : "http://localhost:3001";
  const [formData, setFormData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  });

  const [responseStatus, setResponseStatus] = useState(null);
  const animationContainer = useRef(null);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { email, firstname, lastname, phone } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formData, trainer: endpoint }),
    };

    fetch(`${baseURL}/api/create-lead`, requestOptions)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          setResponseStatus(response.status);
          return response.json();
        } else {
          throw new Error("Server response was not ok.");
        }
      })
      .then((data) => {
        console.log(data);
        setFormData({
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        });
        setIsSubmitted(true);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    console.log("useEffect called");
    if (responseStatus === 201) {
      const animation = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: animationData,
      });
      console.log("animation object created", animation);
      return () => {
        animation.destroy();
        console.log("animation destroyed");
      };
    }
  }, [responseStatus]);

  useEffect(() => {
    if (responseStatus !== 201) {
      // Reset the form fields if the response status changes
      setFormData({
        email: "",
        firstname: "",
        lastname: "",
        message: "",
      });
    }
  }, [responseStatus]);

  const isFormValid = email && firstname && lastname && phone;

  return (
    <div className="form__wrapper">
      <div className="form__container">
        <h2 className="form__title">Reserva tu sesión de prueba</h2>
        {!isSubmitted && (
          <form className="form__freeclass" onSubmit={(e) => handleSubmit(e)}>
            <div className="form-group contact-field">
              <label className="form-group_label" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => handleChange(e)}
                className="form-control"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                // placeholder="Tu Email"
              />
            </div>
            <div className="form-group contact-field">
              <label className="form-group_label" htmlFor="firstname">
                Nombre
              </label>
              <input
                type="text"
                name="firstname"
                value={firstname}
                onChange={(e) => handleChange(e)}
                className="form-control"
                required
                // placeholder="Tu Nombre"
              />
            </div>
            <div className="form-group contact-field">
              <label className="form-group_label" htmlFor="lastname">
                Apellido
              </label>
              <input
                type="text"
                name="lastname"
                value={lastname}
                onChange={(e) => handleChange(e)}
                className="form-control"
                required
                // placeholder="Tu Apellido"
              />
            </div>
            <div className="form-group contact-field">
              <label className="form-group_label" htmlFor="phone">
                Teléfono
              </label>
              <input
                type="phone"
                name="phone"
                value={phone}
                onChange={(e) => handleChange(e)}
                className="form-control"
                required
                // placeholder="Tu Teléfono"
              />
            </div>
            <div className="form-group btn-block">
              <button type="submit" className="btn" disabled={!isFormValid}>
                Reservar
              </button>
            </div>
          </form>
        )}
        {responseStatus === 201 && (
          <div className="lottie-overlay">
            <div className="lottie-container" ref={animationContainer}></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Form;
