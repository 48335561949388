import React from "react";
import { useNavigate } from "react-router-dom";
import "../blocks/Main.css";

function Main() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/reserva-una-sesion-de-prueba");
  };
  return (
    <main className="main">
      <div className="main__container">
        <div className="main__hero">
          <div className="main__hero-wrapper">
            <h1 className="main__hero-title">
              Entrenamiento Personal en Mallorca
            </h1>

            <h2 className="main__hero-subtitle">
              Fitness para todos los niveles
            </h2>
            <button className="main__hero-button" onClick={handleButtonClick}>
              Reserva una sesión de prueba
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
