import React from "react";
import "../../blocks/Packages/TrainerProfile.css";
import "@fortawesome/fontawesome-free/css/all.css";

import Form from "../Form";
import CardModule from "../CardModule";

import image1 from "../../images/trainers/juancarlos_thecrew.jpg";

function JuanCarlos() {
  // const reviews = [
  //   {
  //     image: image1,
  //     name: "Sergio de Paula",
  //     text: "Juan Carlos es un gran entrenador personal.",
  //   },
  //   {
  //     image: image3,
  //     name: "Claudia Stretthoober",
  //     text: "Las sesiones con Juan Carlos son muy divertidas.",
  //   },
  // ];

  return (
    <>
      {/* ------ BLOCK NEW HEADER -------- */}

      <div className="profile_intro_container">
        <div className="profile_intro_data">
          <div className="profile_intro_card">
            <div className="card_body">
              <div>
                <img
                  className="card_image"
                  src={image1}
                  alt="Juan Carlos González"
                />
              </div>
              <div>
                <h2 className="card_title">
                  Juan Carlos
                  <br />
                  González
                </h2>
                <p className="card_subtitle">Entrenador Personal</p>
                {/* <div className="social_icons">
                 
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fas fa-link"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="profile_intro_contact">
          <div className="form_container">
            <div className="contact_form_profilepage">
              <Form endpoint="juancarlos" />
            </div>
          </div>
        </div>
        <div className="profile_intro_description">
          <div className="profile_intro_description_text">
            <p className="profile_intro_description_content">
              La misión aquí es clara y directa: dotarte de las herramientas
              necesarias para que te apoderes de tu bienestar físico y mental.
              El objetivo es que aprendas la técnica perfecta, desarrolles una
              relación saludable con la alimentación y construyas hábitos de
              entrenamiento que perduren en el tiempo.
            </p>
            <p className="profile_intro_description_content">
              Vamos a desmantelar mitos que pudieron haber frenado tu progreso
              anteriormente, proporcionándote una ruta clara hacia el éxito
              sostenible.
            </p>
            <p className="profile_intro_description_section">
              Un Entrenador Dedicado a Tu Transformación
            </p>
            <p className="profile_intro_description_content">
              Desde sus inicios en 2005, Juan Carlos ha forjado un camino
              imparable en el mundo del fitness.
            </p>
            <p className="profile_intro_description_content">
              A una edad temprana, enfrentó personalmente la ardua batalla
              contra el sobrepeso, una experiencia que no solo le permitió
              transformar su propio cuerpo, sino que también sembró una pasión
              indeleble por el bienestar físico.
            </p>
            <p className="profile_intro_description_content">
              Este camino de autodescubrimiento y superación le brindó una
              perspectiva única, marcada por la comprensión profunda de los
              desafíos que implica perder peso y, lo más importante, mantener
              una vida saludable. Esta vivencia personal lo ha equipado con una
              empatía genuina y un conjunto de habilidades especializadas para
              guiar a otros en su camino hacia la transformación.
            </p>
            <p className="profile_intro_description_content">
              Al colaborar con Juan Carlos, encontrarás un mentor que no solo
              habla desde una rica trayectoria profesional, sino también desde
              una auténtica experiencia de superación. Su enfoque está diseñado
              no solo para ayudarte a alcanzar tus metas, sino para asegurarse
              de que cada paso que das esté fundamentado en el respeto por tu
              bienestar individual y la celebración de cada pequeña victoria en
              tu camino hacia una mejor versión de ti mismo.
            </p>
            <p className="profile_intro_description_content">
              Su amplia experiencia profesional incluye la creación y
              reinventación de varios centros de entrenamiento, siempre con una
              visión centrada en el bienestar personalizado. Esta vasta
              experiencia se traduce en un enfoque de entrenamiento enriquecido
              que coloca tus necesidades y objetivos en el núcleo de cada
              programa de entrenamiento.
            </p>
          </div>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <div class="profile_intro_content">
            <h2>Bonos de Entrenamiento Personal</h2>
            <h3 className="profile_intro_services_package">Bono Basic</h3>
            <ul>
              <p>
                Horas contratadas, valoración y adaptación de entrenos de fuerza
                para crear el camino y así consaguir tuS2objetivos. Rutina de 4
                semanas de fuerza con pesos y repeticiones apuntados en las
                tablas. Rutina de entreno para 4 semanas sin material donde
                podrás realizarlo donde quieras cuando quieras y con quien
                quieras (complemento al entreno de fuerza).
              </p>
              <li>1 Entreno 90€ (2 horas)</li>
              <li>4 Entrenos 140€ (1 entreno a la semana)</li>
              <li>8 Entrenos 260€ (2 entrenos a la semana)</li>
              <li>12 Entrenos 360€ (3 entrenos a la semana)</li>
            </ul>

            <h3 className="profile_intro_services_package">Bono Premium</h3>
            <ul>
              <p>
                Horas contratadas, valoración y adaptación de entrenos de fuerza
                para crear el camino y así consaguir tuS2objetivos. Servicio de
                mediciones corporales. Seguimiento alimenticio (control de
                macros) y tabla con +de 90 alimentos. Rutina de 4 semanas de
                fuerza con pesos y repeticiones apuntados en las tablas.
                Seguimiento vía whatsap de la rutina de fuerza. Rutina de
                entreno para 4 semanas sin material donde podrás realizarlo
                donde quieras cuando quieras y con quien quieras (complemento al
                entreno de fuerza).
              </p>
              <li>1 Entreno 60€ (1 hora)</li>
              <li>4 Entrenos 165€ (1 entreno a la semana)</li>
              <li>8 Entrenos 295€ (2 entrenos a la semana)</li>
              <li>12 Entrenos 395€ (3 entrenos a la semana)</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <h2>Bonos Entrenamiento en Remoto (plan de 100 días)</h2>
          <ul>
            <li>
              12 vídeo llamadas , valoración y adaptación de entrenos de fuerza
              para crear el camino y así consaguir tuS2objetivos.
            </li>
            <li>
              Seguimiento alimenticio (control de macros) y tabla con +de 90
              alimentos.
            </li>
            <li>
              Rutina de 4 semanas de fuerza con pesos y repeticiones apuntados
              en las tablas
            </li>
            <li>Seguimiento vía whatsap de la rutina de fuerza.</li>
            <li>
              Rutina de entreno para 4 semanas sin material donde podrás
              realizarlo donde quieras cuando quieras y con quien quieras
              (complemento al entreno de fuerza)
            </li>
          </ul>
          <h3 className="profile_intro_services_package">Tarifa</h3>
          <ul>
            <li>
              <li>Pago único 450€</li>
              <li>En dos pagos 270€ por pago (meses consecutivos)</li>
              <li>En 3 pagos 180€ por pago (meses consecutivos)</li>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <h2> Preparación para competición</h2>
          <p>
            Preparacion para competicion tanto para personas que vivan en la
            isla como para personas que vivan fuera.
          </p>
          <p>
            Todo incluido más un entreno mensual en mi centro. Aprendizaje de la
            técnica con ejercicios adaptados al cliente. Mediciones corporales,
            asesoramiento alimenticio, listado con casi 100 alimentos, control
            de macros. Rutina con repeticiones y pesos apuntadas en la rutina
            para todo el año con el objetivo de la competición. Si no puedes
            asistir fisicamente alguna de las horas se hará una videollamada.
          </p>
          <h3 className="profile_intro_services_package">Bono Silver</h3>
          <ul>
            <li>1 Pago anual: 1.200€</li>
            <li>Tarifa anual en 2 pagos (cada 6 meses): 1.400€</li>
            <li>Tarifa anual en 3 pagos (cada 3 meses): 1.560€</li>
          </ul>
        </div>
      </div>
      <div className="profile_intro_services">
        <div className="profile_intro_services_container">
          <Form endpoint="juancarlos" />
        </div>
      </div>
      {/* ------ BLOCK OTHER TRAINERS -------- */}
      <div className="package-page__block-other-trainers">
        <CardModule />
      </div>
    </>
  );
}

export default JuanCarlos;
