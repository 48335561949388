import React from "react";
import "../../blocks/Footer/Logo.css";

import logoicon from "../../images/logoiconthecrew.svg";

function Logo() {
  return (
    <div className="footer__section">
      <img src={logoicon} alt="Company Logo" className="footer__logo" />
    </div>
  );
}

export default Logo;
