import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "../blocks/Header.css";

import logo from "../images/logoTheCrew.svg";

function Header() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/reserva-una-sesion-de-prueba");
  };

  return (
    <header className="header">
      <div className="header__container">
        <div className="header__logo">
          <Link to="/">
            <img className="header__logo-image" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="header__menu">
          <ul className="header__menu-list">
            <li className="header__menu-list-item">
              <Link
                className="burgerheader__menu-list-item-link"
                to="/entrenadores-personales"
              >
                Entrenadores Personales
              </Link>
              <Link
                className="burgerheader__menu-list-item-link"
                to="/sobre-nosotros"
              >
                Sobre nosotros
              </Link>
              <Link
                className="burgerheader__menu-list-item-link"
                to="/instalaciones"
              >
                El centro
              </Link>
              <button
                className="header__menu-list-item-button"
                onClick={handleButtonClick}
              >
                Reserva una sesión de prueba
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
