import React from "react";
import { Link } from "react-router-dom";

import "../../blocks/Footer/PackagesAvailable.css";

function PackagesAvailable() {
  return (
    <div className="footer__section">
      <ul>
        <li>
          <Link to="/juancarlos">Juan Carlos González</Link>
        </li>
        <li>
          <Link to="/xavilift">Xavi Lift</Link>
        </li>
      </ul>
    </div>
  );
}

export default PackagesAvailable;
