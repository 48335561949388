import React from "react";
import { Link } from "react-router-dom";
import "../blocks/Card.css";

function Card({ image, title, description, buttonText, to }) {
  return (
    <Link to={to} className="link">
      <div className="card">
        <img src={image} alt="" className="card__image" />
        <h3 className="card__title">{title}</h3>
        <ul className="card__description">
          {description.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <button className="card__button">{buttonText}</button>
      </div>
    </Link>
  );
}

export default Card;
