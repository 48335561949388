import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import Main from "./Main";
import AboutUs from "./AboutUs";
import CardModule from "./CardModule";
import ReviewModule from "./ReviewModule";
import ImageGrid from "./ImageGrid";
import FetchPosts from "./FetchPosts";
import BlogPost from "./BlogPost";

function PageContent() {
  // const location = useLocation();

  // const reviews = [
  //   {
  //     image: image1,
  //     name: "Sergio de Paula",
  //     text: "Juan Carlos es un gran entrenador personal.",
  //   },
  //   {
  //     image: image3,
  //     name: "Claudia Stretthoober",
  //     text: "Las sesiones con Juan Carlos son muy divertidas.",
  //   },
  // ];

  const [selectedPost, setSelectedPost] = useState(null);

  const handleSelectPost = (post) => {
    setSelectedPost(post);
  };

  return (
    <>
      <Main />

      <CardModule />
      <AboutUs />
      <FetchPosts />
      {/* <ReviewModule reviews={reviews} /> */}
      <ImageGrid />
    </>
  );
}

export default PageContent;
