import React from "react";
import "../../blocks/Footer/LegalStuff.css";

function LegalStuff() {
  return (
    <div className="footer__section">
      <ul>
        <li>
          <a
            href="https://www.thecrewmallorca.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <li>Terms of Service</li>
      </ul>
    </div>
  );
}

export default LegalStuff;
