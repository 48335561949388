import React from "react";
import PackagesAvailable from "../components/Footer/PackagesAvailable";
import ContactDetails from "../components/Footer/ContactDetails";
import DevelopedBy from "../components/Footer/Developedby";
import Logo from "../components/Footer/Logo";
import LegalStuff from "../components/Footer/LegalStuff";
import "../blocks/Footer.css";

function Footer() {
  return (
    <footer className="main__footer">
      <Logo />
      <PackagesAvailable />
      <ContactDetails />
      <LegalStuff />
      <DevelopedBy />
    </footer>
  );
}

export default Footer;
