import React from "react";

import "../blocks/AboutUs.css";

function AboutUs() {
  return (
    <section id="about-us" className="about-us">
      <div className="about-us__image">
        <div className="about-us__title">
          <h2>Consigue tus Objetivos</h2>
          {/* <h3>Entrenadores Personales en Mallorca</h3> */}
        </div>
      </div>
      <div className="about-us__content">
        <h3>
          Descubre THE<strong>CREW</strong> - Entrenadores Personales en
          Mallorca
        </h3>

        <p>
          En nuestro centro de fitness, entendemos que cada individuo es único y
          posee diferentes necesidades y objetivos en lo que respecta a su salud
          y bienestar.
        </p>
        <p>
          Por ello, nuestro colectivo de entrenadores se dedica a trabajar codo
          a codo contigo para desarrollar un plan de entrenamiento que no solo
          se alinee con tus metas personales, sino que también se integre de
          forma armónica con tu estilo de vida.
        </p>
        <p>
          Ya sea que busques ganar masa muscular, mejorar tu condición
          cardiovascular, o simplemente mantenerte activo, tenemos a un experto
          que puede ayudarte a transitar ese camino de manera saludable y
          sostenible.
        </p>
        <p>
          <strong>
            Conoce a nuestros expertos y elige el que mejor se adapte a ti
          </strong>
        </p>
        <p>
          Te invitamos a conocer a nuestro diverso equipo de profesionales
          especializados en diferentes áreas del fitness.
        </p>
        <p>
          Todos ellos comparten una metodología enfocada en el logro de tus
          objetivos de la forma más eficiente y segura posible, garantizando
          resultados duraderos.
        </p>
        <p>
          Concreta una entrevista gratuita y descubre cómo podemos ajustarnos a
          tus necesidades específicas, proporcionándote la guía experta que te
          llevará a alcanzar esa versión más saludable y feliz de ti mismo.
        </p>
        <p>
          En nuestro centro, tu bienestar es nuestra principal misión, y estamos
          dispuestos a demostrártelo con nuestra dedicación y compromiso hacia
          tu salud.
        </p>
        <p>
          En <strong>The Crew Personal Trainers</strong>, el compromiso es
          ayudarte a alcanzar tus metas de una manera que sea tanto saludable
          como sostenible. Aquí, cada paso que das hacia tus objetivos está
          respaldado por años de experiencia y una dedicación inquebrantable a
          tu bienestar.
        </p>
        <p>
          Prepárate para comenzar una colaboración que transformará tu vida,
          llevándote a una versión más saludable y feliz de ti mismo. No esperes
          más, el inicio de tu transformación está a solo un contacto de
          distancia.
        </p>
      </div>
    </section>
  );
}

export default AboutUs;
