import React from "react";
import "../../blocks/Footer/DevelopedBy.css";

function DevelopedBy() {
  return (
    <div className="footer__section">
      <p>
        Desarrollado con 🫶 por{" "}
        <a
          href="https://www.miguelmarketer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Miguel Marketer
        </a>
      </p>
    </div>
  );
}

export default DevelopedBy;
