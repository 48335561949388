// ContactDetails.js

import React from "react";
import "../../blocks/Footer/ContactDetails.css";

function ContactDetails() {
  return (
    <div className="footer__section">
      <ul>
        <li>Calle Cardenal Rosell, 20</li>
        <li>07007 - Es Coll d'en Rabassa</li>
        {/* <li>Phone: +1 234 567 890</li> */}
      </ul>
    </div>
  );
}

export default ContactDetails;
