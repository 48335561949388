import React from "react";
import "../blocks/ImageGrid.css";

import image1 from "../images/facilities/thecrewmallorca_1 copy.jpeg";
import image2 from "../images/facilities/thecrewmallorca_2 copy.jpeg";
import image3 from "../images/facilities/thecrewmallorca_3 copy.jpeg";
import image4 from "../images/facilities/thecrewmallorca_5 copy.jpeg";
import image5 from "../images/facilities/thecrewmallorca_6 copy.jpeg";
import image6 from "../images/facilities/thecrewmallorca_7 copy.jpeg";

function ImageGrid() {
  const images = [image1, image2, image3, image4, image5, image6];
  return (
    <section id="facilities">
      <h2 className="image-grid__title">Nuestras instalaciones</h2>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-grid__item">
            <img src={image} alt={`Fitness center ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default ImageGrid;
